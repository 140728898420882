<template>
  <div>
    <h4> Fiche Analyse - Type</h4>
    <data-table
      :data-source="sortedTypes"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-fiche-type"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
          
          
          
    <modal
      title="Ajout d'un type de fiche analyse"
      id="add-fiche-type"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            id="add-code"
            name="add-code"
            type="text"
            class="form-control"
            v-model="code"
          >
        </div>
        
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
  
    <modal
      title="Modification du type de fiche analyse"
      id="update-fiche-type"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="update-code">Code</label>
          <input
            id="update-code"
            name="update-code"
            type="text"
            class="form-control"
            v-model="code"
          >
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../../components/dataTable/local.vue'
  import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
  import Modal from '../../../../components/modal.vue'
  import { ADD_TYPE_FICHE_ANAYLSE, UPDATE_TYPE_FICHE_ANAYLSE } from '../../../../graphql/ficheAnalyse'
  const Actions = () => import('../../../../components/admin/sanitaire/ficheAnalyse/typeAction.vue')

  export default {
      components: { DataTable, Modal},
      data(){
          return {
              libelle: null,
              code: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.code = this.selectedObject.code
                  }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.code = null
              this.has_error = false
              this.error_msg = null
          },
          adding(){
              let data = {
                  libelle: this.libelle,
                  code: this.code
              }
              this.$apollo.mutate({
                  mutation: ADD_TYPE_FICHE_ANAYLSE,
                  variables: {
                      "type": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Type fiche d'analyse ${this.libelle} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editing(){
              let data = {
                  libelle: this.libelle,
                  code: this.code
              }
              this.$apollo.mutate({
                  mutation: UPDATE_TYPE_FICHE_ANAYLSE,
                  variables: {
                      "type": {
                          ...data
                      },
                      "uid": this.selectedObject.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Type fiche d'analyse ${this.libelle} on ${this.selectedObject.uid} updated successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              selectedObject: 'selectedObject',
              types: 'sanitaire/typeFicheAnalyses'
          }),
          sortedTypes(){
            return [...this.types].sort((a,b) => a.libelle.localeCompare(b.libelle))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true},
                  {label: 'Code', name: 'code', type: TEXT_TYPE },
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>